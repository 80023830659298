var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app"},[_c('div',{staticClass:"min"},[_vm._m(0),_c('div',{staticClass:"button"},[_c('van-button',{attrs:{"type":"info"},on:{"click":_vm.openScan}},[_c('van-icon',{attrs:{"name":"scan","color":"#fff"}}),_vm._v(" 扫码查看 ")],1)],1),_c('div',{staticClass:"overview"},[_c('p',{staticClass:"gs"},[_vm._v("概览")]),_c('div',{staticClass:"overview-top"},[_c('div',{staticClass:"overview-item"},[_vm._m(1),_c('span',{staticClass:"bumber"},[_vm._v(_vm._s(_vm.elevatorCount))]),_c('span',[_vm._v("部")])]),_c('div',{staticClass:"overview-item"},[_vm._m(2),_c('span',{staticClass:"bumber"},[_vm._v(_vm._s(_vm.maintenanceCount))]),_c('span',[_vm._v("个")])])]),_c('div',{staticClass:"overview-bottom"},[_c('div',{staticClass:"overview-item"},[_vm._m(3),_c('span',{staticClass:"bumber"},[_vm._v(_vm._s(_vm.companyCount))]),_c('span',[_vm._v("个")])]),_c('div',{staticClass:"overview-item"},[_vm._m(4),_c('span',{staticClass:"bumber"},[_vm._v(_vm._s(_vm.userOrderCount))]),_c('span',[_vm._v("个")])])])]),_c('div',{staticClass:"query"},[_c('div',{staticClass:"query-item",staticStyle:{"margin-right":"10px"}},[_c('div',[_c('p',[_vm._v("查询电梯")]),_c('van-button',{attrs:{"type":"info"},on:{"click":_vm.onSubmit}},[_vm._v(" 立即查询 "),_c('div',{staticClass:"kailong"})])],1),_c('img',{attrs:{"src":require("../../assets/query.png"),"alt":""}})]),_c('div',{staticClass:"query-item"},[_c('div',[_c('p',[_vm._v("维保单位查询")]),_c('van-button',{attrs:{"type":"info"},on:{"click":_vm.unitSubmit}},[_vm._v(" 立即查询 "),_c('div',{staticClass:"kailong"})])],1),_c('img',{attrs:{"src":require("../../assets/maint.png"),"alt":""}})])]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("../../assets/banner.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../assets/total.png"),"alt":""}}),_c('span',{staticClass:"total"},[_vm._v("电梯总数")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../assets/maintenance.svg"),"alt":""}}),_c('span',{staticClass:"total"},[_vm._v("维保单位")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../assets/unit.svg"),"alt":""}}),_c('span',{staticClass:"total"},[_vm._v("使用单位")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../assets/deal.svg"),"alt":""}}),_c('span',{staticClass:"total"},[_vm._v("近30天处理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"information"},[_c('p',[_vm._v("电梯云资讯")]),_c('span',[_vm._v(" 更多 "),_c('img',{attrs:{"src":require("../../assets/arror.png"),"alt":""}})])])
}]

export { render, staticRenderFns }