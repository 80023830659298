<template>
	<div class="app">
		<!-- <van-nav-bar title="电梯物联网云平台" left-arrow>
      <template #right>
        <van-icon name="ellipsis" size="20" color="#000" />
      </template>
    </van-nav-bar> -->
		<div class="min">
			<div class="banner">
				<img src="../../assets/banner.png" alt="">
			</div>
			<div class="button">
				<van-button type="info" @click="openScan">
					<van-icon name="scan" color="#fff" />
					扫码查看
				</van-button>
			</div>
			<div class="overview">
				<p class="gs">概览</p>
				<div class="overview-top">
					<div class="overview-item">
						<div>
							<img src="../../assets/total.png" alt="">
							<span class="total">电梯总数</span>
						</div>
						<span class="bumber">{{elevatorCount}}</span><span>部</span>
					</div>
					<div class="overview-item">
						<div>
							<img src="../../assets/maintenance.svg" alt="">
							<span class="total">维保单位</span>
						</div>
						<span class="bumber">{{maintenanceCount}}</span><span>个</span>
					</div>
				</div>
				<div class="overview-bottom">
					<div class="overview-item">
						<div>
							<img src="../../assets/unit.svg" alt="">
							<span class="total">使用单位</span>
						</div>
						<span class="bumber">{{companyCount}}</span><span>个</span>
					</div>
					<div class="overview-item">
						<div>
							<img src="../../assets/deal.svg" alt="">
							<span class="total">近30天处理</span>
						</div>
						<span class="bumber">{{userOrderCount}}</span><span>个</span>
					</div>
				</div>
			</div>
			<div class="query">
				<div class="query-item" style="margin-right: 10px;">
					<div>
						<p>查询电梯</p>
						<van-button type="info" @click="onSubmit">
							立即查询
							<div class="kailong"></div>
						</van-button>
					</div>
					<img src="../../assets/query.png" alt="">
				</div>
				<div class="query-item">
					<div>
						<p>维保单位查询</p>
						<van-button type="info" @click="unitSubmit">
							立即查询
							<div class="kailong"></div>
						</van-button>
					</div>
					<img src="../../assets/maint.png" alt="">
				</div>
			</div>
			<div class="information">
				<p>电梯云资讯</p>
				<span>
					更多
					<img src="../../assets/arror.png" alt="">
				</span>
			</div>
			<!-- <div class="main">
				<div>
					<p>96111标识牌绑定流程</p>
					<span>2020-01-10</span>
				</div>
				<img src="../../assets/banner.png" alt="">
			</div> -->
		</div>
	</div>
</template>

<script>
import {
  getHomeData,
  wxJsJdkSignature,
  getWxUserInfo
} from '@/api/public'
import {
  scanResultAnalysis,
  setWxUser,
  getWxUser
} from '@/utils'
import wx from 'weixin-jsapi'
import {
  Toast
} from 'vant'
import preventBack from 'vue-prevent-browser-back' // 组件内单独引入
export default {
  mixins: [preventBack], // 注入
  name: 'home',
  data () {
    return {
      elevatorCount: 0,
      maintenanceCount: 0,
      companyCount: 0,
      userOrderCount: 0,
      code: ''
    }
  },
  created () {
    // this.getCode();
    this.getHomeData()
  },
  methods: {
    onSubmit () {
      this.$router.push('theQueryDt')
    },
    unitSubmit () {
      this.$router.push('theQueryWbdw')
    },
    async getHomeData () {
      try {
        getHomeData({}).then(response => {
          this.elevatorCount = response.data.elevatorCount
          this.maintenanceCount = response.data.maintenanceCount
          this.companyCount = response.data.companyCount
          this.userOrderCount = response.data.userOrderCount
        })
      } catch (error) {
        // console.info(error);
      }
    },

    openScan () {
      const that = this
      wx.ready(function () {
        wx.checkJsApi({
          jsApiList: ['scanQRCode'],
          // 需要检测的JS接口列表，所有JS接口列表见附录2,
          success: function (res) {
            // 以键值对的形式返回，可用的api值true，不可用为false
            // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
            if (res.checkResult.scanQRCode == true) {
              wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
                success: function (res) {
                  var result = res
                    .resultStr // 当needResult 为 1 时，扫码返回的结果
                  that.scanResult(result)
                }
              })
            } else {
              Toast.fail('扫码不可用')
            }
          }
        })
      })

      wx.error(function (response) {
        // console.info(response);
      })
    },
    scanResult (result) {
      try {
        const code = scanResultAnalysis(result)
        if (code) {
          this.$router.push({
            path: 'detailsDt',
            query: {
              id: code
            }
          })
        } else {
          Toast(result)
          this.$router.push({
            path: 'scanTheCode',
            query: {
              result: result
            }
          })
        }
      } catch (error) {
        // console.info(error);
      }
    }

  }
}
</script>

<style scoped>
	* {
		box-sizing: border-box;
	}

	p {
		margin: 0;
	}

	.min {
		background-color: #F9F9F9;
		padding: 0 10px;
	}

	.min .banner {
		width: 100%;
		margin-top: 10px;
	}

	.min .banner img {
		width: 100%;
		height: 160px;
		display: block;
	}

	.button button {
		width: 100%;
		height: 46px;
		line-height: 46px;
		border-radius: 8px;
		font-size: 15px;
		margin: 15px 0;
	}

	.button .van-icon {
		font-size: 15px;
		position: relative;
		top: 3px;
	}

	.overview {
		/* width: 345px;
  height: 177.5px; */
		background-color: #fff;
		padding: 15px;
		border-radius: 10px;
		/* padding: 0 15px 0 15px; */
	}

	.overview-bottom,
	.overview-top {
		display: flex;
		justify-content: space-between;
	}

	.overview-bottom {
		margin-top: 4px;
	}

	.overview .overview-item {
		width: 315px;
	}

	.overview .gs {
		font-size: 16px;
		font-weight: 500;
		color: #383A3D;
		padding: 5px 0;
	}

	.overview img {
		width: 13px;
		height: 13px;
		position: relative;
		top: 2px;
	}

	.overview span {
		font-size: 13px;
		color: #B8B8B8;
	}

	.overview .total {
		color: #383A3D;
		margin-left: 8px;
	}

	.overview .bumber {
		margin-left: 21px;
		font-size: 24px;
		color: #383A3D;
		font-weight: bold;
		margin-right: 5px;
	}

	.query {
		display: flex;
		justify-content: space-between;
		margin: 10px 0;
	}

	.query .query-item {
		/* width: 166px;
  height: 90px; */
		background-color: #fff;
		padding: 16px;
		display: flex;
		justify-content: space-between;
		border-radius: 10px;
		flex: 1;
	}

	.query .query-item p {
		font-size: 16px;
		color: #383A3D;
		font-weight: 500;
		white-space: nowrap;
		position: absolute;
	}

	.query .van-button--normal {
		width: 64px;
		height: 20px;
		color: #fff;
		font-size: 10px;
		padding: 0;
		line-height: 20px;
		margin-top: 30px;
	}

	.query img {
		width: 74px;
		height: 74px;
	}

	.kailong {
		width: 0;
		height: 0;
		border-top: 4px solid transparent;
		border-bottom: 4px solid transparent;
		border-left: 4px solid #fff;
		position: absolute;
		top: 5px;
		right: 1px;
	}

	.information {
		display: flex;
		justify-content: space-between;
	}

	.information p {
		font-size: 16px;
		font-weight: 500;
		color: #383A3D;
	}

	.information span {
		font-size: 13px;
		font-weight: 400;
		color: #B8B8B8;
		margin-top: 4px;
	}

	.information img {
		width: 13px;
		height: 13px;
		position: relative;
		top: 2px;
		border: 1px solid #cfcfcf;
	}

	.main {
		width: 100%;
		height: 100px;
		background: #FFFFFF;
		margin: 15px 0;
		padding: 15px;
		display: flex;
		justify-content: space-between;
	}

	.main img {
		width: 100px;
		height: 70px;
	}

	.main p {
		font-size: 14px;
		font-weight: 500;
		color: #383A3D;
	}

	.main span {
		font-size: 12px;
		font-weight: 400;
		color: #979EB1;
	}
</style>
